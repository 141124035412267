<template>
  <div class="card-grid">
    <ChartCard chartType="bar-horizontal" questionType="work_errands" full />
    <ChartCard
      chartType="bar-horizontal"
      questionType="errands_transportation_method"
      full
    />
    <ChartCard
      chartType="text"
      questionType="errands_worktrips_reasoning"
      full
    />
  </div>
</template>
<script>
import ChartCard from "../chart-cards/ChartCard.vue";
export default {
  metaInfo() {
    return {
      title: this.$t("dashboard.nav.business_trips"),
    };
  },
  components: { ChartCard },
};
</script>
<style lang=""></style>
